.checkbox {
  color: #fff;
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  span.checkboxLabel {
    font-size: 19px;
    font-weight: 500;
    user-select: none;
  }

  input[type='checkbox'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  span.checkboxBox {
    width: 17px;
    height: 17px;
    border-radius: 0;
    border: 1px solid #979797;
    background: #fff;
    display: flex;
    margin-right: 10px;
    position: relative;

    &.checked {
      background: #94f6d0;

      &::after {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5L4.66667 8L10 2' stroke='%231A1A3C' stroke-width='2.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 8px;
        height: 8px;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
