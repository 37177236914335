// AreaButton classes from Tailwind CSS
// flex flex-col shadow-banner bg-magenta select-none ${areaSize} font-action font-bold ${fontSize} text-center text-sunny uppercase italic
.area-button {
    display: flex; // flex
    flex-direction: column; // flex-col
    background-color: rgb(232 60 141); // bg-magenta
    color: rgb(253 239 80); // text-sunny
    text-align: center; // text-center
    text-transform: uppercase; // uppercase
    font-family: "Bebas Neue";

    // Top divider line
    // border-top: 2px solid rgb(255,255,255,.5);

    --tw-shadow: 0 3px 1px 0 rgb(0,0,0,.5);
    --tw-shadow-color: rgb(255 255 255);
    --tw-shadow-colored: 0 3px 1px 0 var(--tw-shadow-color);
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    cursor: pointer;
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
