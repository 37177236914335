html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000;
    background-image: url("../../images/bg.png"),
        linear-gradient(91deg, rgba(0, 0, 0, 0) 50%, #000 99%),
        linear-gradient(268deg, rgba(27, 5, 5, 0) 50%, #070513 12%),
        linear-gradient(to bottom, rgba(34, 21, 67, 0) 52%, #000 93%),
        linear-gradient(to bottom, #000, rgba(59, 48, 87, 0) 44%);

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 100vh;
    font-family: $base-font-family;
}

// React App root element
// Hide x-scrollbar
#root {
    width: 100%;
    height: auto;
    overflow-x: hidden;
}

.App {
    height: auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
}

.container {
    width: 100%;
    min-height: calc(100vh - 400px);
    max-width: 1100px;
    margin: 0 auto 30px;
    padding: 20px;
    display: flex;

    @include desktop {
        width: 80%;
    }

    @include tablet {
        width: 60%;
    }

    @include phone {
        width: 90%;
    }

    &.team-card {
        max-width: 1400px;
        @media (max-width: 1400px) {
            max-width: 97%;
        }
    }

    .heading {
        width: 100%;
        margin: auto;

        .heading-action {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
        }

        .title {
            font-size: 64.2px;
            font-weight: 900;
            line-height: 1.04;
            text-align: center;
            color: $white;
            text-shadow: 0 6px 51px rgba(0, 0, 0, 0.5);
            text-transform: uppercase;
            word-spacing: 10px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: -17px;
                left: 0;
                right: 0;
                border-bottom: 6px solid #e2c600;
                max-width: 253px;
                margin: auto;
            }

            &.error {
                margin-bottom: 0;
                font-size: 47px;

                &::after {
                  display: none;
                }
            }

            @include tablet {
                font-size: 48px;
                line-height: 1.14;
            }

            @include phone {
                font-size: 32.1px;
            }
        } // title

        .subtitle {
            position: relative;
            text-shadow: 0 6px 51px rgba(0, 0, 0, 0.5);
            font-size: 20px;
            text-align: center;
            color: $white;
            text-transform: uppercase;

            span {
                font-weight: 800;
            }

            @include tablet {
                font-size: 16px;
            }

            &.error {
                font-size: 47px;
                font-weight: 900;
                color: #f50084;
          
                &::after {
                    content: "";
                    position: absolute;
                    bottom: -35px;
                    left: 0;
                    right: 0;
                    border-bottom: 10px solid #e2c600;
                    max-width: 210px;
                    margin: auto;
                }
            }
        }
    } // heading

    .section-title {
        font-weight: 900;
        font-size: 62px;
        line-height: 63px;
        text-align: center;
        letter-spacing: 0.51134px;
        text-transform: uppercase;

        @include phone {
            font-size: 38.2966px;
            line-height: 39px;
        }

        @include small-phone {
            font-size: 32px;
            line-height: 35px;
        }
    } // section-title

    .alert {
        background-color: #f57e7e;
        font-size: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        padding: 10px;

        span {
            margin-left: 10px;
        }

        @include tablet {
            font-size: 16px;
        }

        @include phone {
            font-size: 12px;
            padding: 6px;

            span {
                margin-left: 5px;
            }
        }
    } // alert

    .center-wrap {
        display: flex;
        justify-content: center;
        margin: auto;
    }
}
