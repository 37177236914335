.lp-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    overflow: hidden;
    width: 100%;
    @include tablet {
        .heading {
            width: 90%;
        }
    }
    &.single-icons {
        margin: auto;
    }

    .lp-monkeys-team {
        display: flex;
        justify-content: center;
        margin: 27px auto;
        width: 100%;
        overflow: hidden;
        &.single-icons {
            .slick-slide,
            .lp-team-link {
                margin-right: 0;
            }
        }
        .slick-slider {
            width: 100%;
            padding: 0 50px;
            .slick-slide {
                margin-right: 15px;
                @include desktop {
                    width: 50%;
                }
                @include tablet {
                    margin-right: 0;
                    width: 100%;
                }
            }
            .slick-next,
            .slick-prev {
                width: 90px;
                height: 90px;
                &::before {
                    font-size: 40px;
                }
            } 
            .slick-disabled {
                cursor: auto;
            }
        }
        .slick-track {
            display: flex;
        }
        &.single-icons {
            .slick-slider{
                .slick-slide {
                    margin-right: 0;
                }
            }
        }
    }
    .lp-team-link {
        color: $white;
        text-decoration: none;
    }
    .lp-monkey-card {
        .lp-monkey-img-wrap {
            position: relative;
            .lp-monkey-img {
                height: 270px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                box-sizing: border-box;
                @include desktop {
                    height: 350px;
                }
                @include tablet {
                    height: 400px;
                }
                @include phone {
                    height: 300px;
                }
            }
            .lp-monkey-name-wrap {
                position: absolute;
                bottom: -20px;
                left: 10%;
                right: 10%;
                margin: auto;
                background-color: $pink;
                color: $white;
                font-size: 20px;
                font-weight: 700;
                line-height: 1.2;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: rotate(-2deg);
                .lp-monkey-name {
                    display: flex;
                    padding: 10px 12px;
                    transform: rotate(2deg);
                }
            }
        }
        .lp-monkey-card-content {
            margin-top: 40px;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: 0.01px;
            text-align: center;
            color: $white;
            display: flex;
            flex-direction: column;
        }
    }
}
