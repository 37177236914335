.hero {
    position: relative;
    // background: radial-gradient(41.36% 39.11% at 27.9% 47.22%, #353587 0%, #000000 100%);
    background-repeat: no-repeat;
    padding-bottom: 100px;

    background-image: url("../../images/hero-bg.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        background-image: url("../../images/hero-line.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 382px;
        height: 271px;
        @include phone {
            display: none;
        }
    }
    @include phone {
        padding-bottom: 0;
    }
}
.hero-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1380px;
    margin: auto;
    @include desktop {
        max-width: 90%;
        margin: auto;
    }
    @include phone {
        flex-direction: column;
    }
    .hero-content {
        width: 50%;
        @include desktop {
            width: 95%;
            margin: auto;
        }
        @include phone {
            width: 100%;
            margin: 0;
            padding: 20px 0;
        }
        h1 {
            img {
                width: 100%;
            }
        }
        .hero-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
        }
        .wrap-center {
            display: flex;
            justify-content: center;
            margin: 67px auto;
            text-align: center;
            button {
                z-index: 10;
            }
            @include phone {
                margin: 40px auto;
                span {
                    font-size: 17px;
                }
            }
        }
        .hero-text {
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.21px;
            text-transform: uppercase;
            color: $white;
            span {
                color: $yellow;
                font-weight: 800;
            }
            @include phone {
                text-align: center;
                font-size: 14px;
                line-height: 21px;
                width: 80%;
                margin: auto;
            }
        }
        .hero-date {
            font-size: 29.2638px;
            line-height: 25px;
            text-align: center;
            text-transform: uppercase;
            color: $white;
            font-weight: 300;
            margin-bottom: 25px;
            @include phone {
                font-size: 18px;
                line-height: 14px;
                margin-bottom: 0;
            }
        }
    }
    .hero-media {
        width: 50%;
        max-width: 690px;
        max-height: 887px;
        @include desktop {
            width: 95%;
            margin: auto;
        }
        @include phone {
            width: 90%;
            margin: 0;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .hero-counter {
        display: flex;
        margin: 25px auto 43px;
        .hero-counter-wrap {
            display: flex;
            align-items: center;
            // transform: rotate(-2deg);
        }
        .inner-wrap {
            display: flex;
            flex-direction: column;
            background-color: $white;
            padding-top: 15px;
            padding-bottom: 10px;
            transform: matrix(1, -0.02, 0.07, 1, 0, 0);
            width: 117px;
            @include phone {
                padding: 5px;
                width: 60px;
            }
            @include small-phone {
                width: 45px;
            }
        }
        .inner-date {
            color: #fc0084;
            font-weight: bold;
            font-size: 79.1566px;
            line-height: 61px;
            text-align: center;
            letter-spacing: 1.68418px;
            text-transform: uppercase;
            width: 116.89px;
            padding-top: 10px;
            transform: matrix(1, 0.02, -0.07, 1, 0, 0);
            @include phone {
                font-size: 40px;
                line-height: 35px;
                width: auto;
                letter-spacing: 0.949938px;
            }
            @include small-phone {
                font-size: 30px;
                line-height: 34px;
            }
        }
        .inner-text {
            font-size: 20.2102px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.152192px;
            text-transform: uppercase;
            color: #010102;
            opacity: 0.8;
            margin-top: 10px;
            transform: matrix(1, 0.02, -0.07, 1, 0, 0);
            @include phone {
                font-size: 14px;
                margin-top: 6px;
                font-size: 11.489px;
                line-height: 10px;
                letter-spacing: 0.0858417px;
                opacity: 0.5;
            }
            @include small-phone {
                font-size: 10px;
            }
        }
        .dots {
            color: $white;
            font-weight: 600;
            font-size: 80.9923px;
            line-height: 61px;
            text-align: center;
            letter-spacing: 1.68418px;
            margin: 6px;
            transform: matrix(1, -0.02, 0.07, 1, 0, 0);
            @include phone {
                font-size: 45px;
                line-height: 35px;
                margin: 3px;
            }
        }
    }
}
