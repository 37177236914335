.tooltip {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 7px 7px;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  border: 1px solid #424261;
  background-color: #111c36;
  width: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  z-index: 15;

  .tooltip-arrow {
    content: "";
    display: block;
    position: absolute;
    border: 1px solid #424261;
    box-sizing: border-box;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    left: 0;
    right: 0;
    top: -6px;
    margin: auto;
    z-index: 1;
    border-bottom: 1px solid #424261;
    border-right: 1px solid #424261;
    background-color: #111c36;
  }
  .tooltip-content {
    z-index: 15;
    background-color: #111c36;
  }
  .tooltip-top {
    padding: 3px 10px;
    border-bottom: 1px solid #2a344a;
    color: $blue;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .tooltip-name {
      margin-right: 30px;
      @include phone {
        margin-right: 10px;
      }
    }
    .tooltip-value {
      font-weight: 600;
    }
    @include phone {
      font-size: 12px;
    }
  }
  .tooltip-row {
    display: flex;
    justify-content: space-between;
    padding: 3px 10px;
    .tooltip-name {
      margin-right: 30px;
      white-space: nowrap;
      @include phone {
        margin-right: 10px;
      }
    }
    .tooltip-value {
      color: $white;
    }
    @include phone {
      font-size: 12px;
    }
  }
}

.text-tooltip {
  position: absolute;
  top: -65%;
  left: 35px;
  transition: opacity 0.3s ease-out;
  width: 300px;
  max-width: inherit;
  background: #111c36;
  border: 1px solid #424261;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px 7px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  font-size: 16px;
  line-height: 18px;
  color: $white;
  font-weight: 400;
  z-index: 1;
  visibility: hidden;
  &::before {
    content: "";
    position: absolute;
    left: -8px;
    background-image: url("../../images/landing/card-info/tooltip.png");
    background-repeat: no-repeat;
    width: 11px;
    height: 22px;
    top: 2px;
    bottom: 0;
    margin: auto;
  }
  @include phone {
    // max-width: 70%;
    top: 120%;
    left: -50px;
    line-height: 1.4;
    &::before {
      content: "";
      position: absolute;
      left: -8px;
      background-image: url("../../images/landing/card-info/tooltip.png");
      background-repeat: no-repeat;
      width: 11px;
      height: 22px;
      top: 2px;
      bottom: 0;
      margin: auto;
      top: -106%;
      left: 53px;
      transform: rotate(90deg)
    }
  }
}

.tooltip-bottom {
  top: 35px;
  right: -25px;
  bottom: 0;
  left: auto;
  transition: opacity 0.3s ease-out;
  width: 350px;
  max-width: inherit;
  height: fit-content;
  &::before {
    content: "";
    position: absolute;
    top: -72px;
    left: auto;
    right: 25px;
    background-image: url("../../images/landing/card-info/tooltip.png");
    background-repeat: no-repeat;
    height: 22px;
    width: 11px;
    margin: auto;
    transform: rotate(90deg);
  }
  @include phone {
    width: 300px;
    &::before {
      top: -104px;
    }
  }
}
