@mixin small-phone {
  @media (max-width: #{$small-phone}) {
    @content;
  }
}
@mixin phone {
  @media (max-width: #{$phone}) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: #{$desktop}) {
    @content;
  }
}
@mixin laptop {
  @media (max-width: #{$laptop}) {
    @content;
  }
}

@mixin button-bg($bg) {
  background: $bg;

  &:hover {
    transition: background-color 0.4s ease;
  }
}

.btn {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    // border-radius: 25px;
    font-family: $base-font-family;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: -0.28px;
    text-align: center;
    padding: 8px 13px;
    text-transform: uppercase;
    line-height: 1;
    cursor: pointer;

    @include tablet() {
        font-size: 12px;
    }

    @include phone() {
        font-size: 9px;
        padding: 6px 8px;
    }
}

.btn-bg-yellow {
    @include button-bg($yellow);
    color: $pink;
    &:hover {
        background-color: $pink;
        color: $white;
    }
}

.btn-bg-mint {
  @include button-bg($mint);
  padding: 13px 35.5px 13px 33.5px;
  color: $dark;
  &:disabled {
    background-color: #ccc;
    span {
      opacity: 0.7;
    }
    &:hover {
      cursor: not-allowed;
    }
  }
  &:hover {
    background: $white;
    cursor: pointer;
  }
  @include tablet() {
    width: 100%;
    font-size: 14px;
  }
}
.btn-bg-mint-action {
  @include button-bg($mint);
  padding: 13px 35.5px 13px 33.5px;
  color: $dark;
  &:disabled {
    background-color: #ccc;
    span {
      opacity: 0.7;
    }
    &:hover {
      cursor: not-allowed;
    }
  }
  &:hover {
    background: $white;
    cursor: pointer;
  }
  @include tablet() {
    font-size: 14px;
  }
}
.btn-bg-pink {
  @include button-bg(
    linear-gradient(77.62deg, #f30186 14.38%, #3d088e 282.46%)
  );
  padding: 13px 35.5px 13px 33.5px;
  color: $white;
  &:hover {
    background: $white;
    color: $pink;
    transition: color 0.4s ease;
  }
  @include tablet() {
    width: 100%;
    font-size: 14px;
  }
}
