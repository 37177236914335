.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 5555;
  //   background-color: rgba($color: #000000, $alpha: 0.5);

  .error-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-modal-body {
    background-color: #1e242b;
    border-radius: 11px;
    width: 673px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: 30px 0 50px;

    .close {
      color: #8d9195;
      font-weight: bold;
      top: 15px;
      right: 15px;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
    }

    .heading {
      margin: 0;
    }

    p {
      color: #fff;
      text-transform: uppercase;
      margin: 70px 0 50px;
      text-align: center;
      padding: 0 60px;
      font-size: 18px;
      line-height: 27px;
    }

    .btn {
      padding: 13px 80.5px 13px 80.5px;
    }
  }
}
