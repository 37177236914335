.incubated {
    position: relative;
    padding: 150px 0 100px;
    &::after {
        content: '';
        position: absolute;
        top: 100px;
        right: 0;
        height: 300px;
        width: 300px;
        background-image: url('../../images/incubated-circle.png');
        background-repeat: no-repeat;
        background-position: top right;
        background-size: contain;
    }
    @include desktop {
        &::after {
            top: 0;
        }
    }
    @include tablet {
        padding-top: 50px;
    }
    @include phone {
        &::after {
            display: none;
        }
    }
    .container {
        flex-direction: column;
    }
    .section-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }
    .incubated-content {
        .incubated-item {
            text-align: center;
            width: 60%;
            margin: 0 auto;
            max-width: 725px;
            @include desktop {
                width: 100%;
            }
        }
        .incubated-button {
            padding: 10px 15px;
            background-repeat: no-repeat;
            transform: rotate(-3deg);
            width: max-content;
            margin: 90px auto 30px auto;
        }
        .incubated-button-text {
            transform: rotate(3deg);
            font-size: 24px;
            font-weight: 700;
            line-height: 38px;
            color: $white;
            @include phone {
                font-size: 20.6489px;
                line-height: 32px;
            }
        }
        .incubated-title {
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 0;
            @include phone {
                font-size: 21px;
                line-height: 28px;
            }
        }
        .incubated-text {
            font-size: 24px;
            font-weight: 400;
            line-height: 35px;
            color: $white;
            @include phone {
                font-size: 17.23px;
                list-height: 20px;
            }
        }
    }
    .incubated-lines {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 240px;
        &::before {
            content: '';
            position: absolute;
            bottom: 200px;
            left: 0;
            height: 140px;
            width: 180px;
            background-image: url('../../images/incubated-line.png');
            background-repeat: no-repeat;
            background-position: top left;
            background-size: contain;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 30px;
            left: 0;
            height: 240px;
            width: 300px;
            background-image: url('../../images/incubated-line1.png');
            background-repeat: no-repeat;
            background-position: top left;
            background-size: contain;
        }
        @include phone {
            display: none;
        }
    }
}