// Colors
$white:  #ffffff;
$yellow: #fff100; // was #e6cb00 | #f0cb40 | #fff100
$orange: #fab529; // was #e6cb00
$red:    #e62700;
$mint:   #94f7d1;
$pink:   #fd0085; // was #fb0084 | #fd0085 | #fc0084
$dark:   #1a1a3c;
$link:   #1da1f2;
$blue:   #1ea1f1;
$black:  #000000;

// Typography
$base-font-family: "Poppins", sans-serif;
$roboto: "Roboto", sans-serif;

// Media
$small-phone: 360px;
$phone:   576px;
$tablet:  840px;  // 800px // was 768px
$desktop: 1120px; // was 1200px
$laptop:  1500px;

@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 900;
}
