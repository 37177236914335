.game {
    background-image: url("../../images/game-bg.png");
    background-repeat: no-repeat;
    color: $white;
    position: relative;
    padding-top: 200px;
    &::before {
        content: "";
        position: absolute;
        top: 100px;
        left: 0;
        background-image: url("../../images/game-line.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 180px;
        height: 280px;
    }
    @include tablet {
        padding-top: 80px;
        &::before {
            display: none;
        }
    }
    .game-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 1460px;
        margin: auto;
        @include laptop {
            max-width: 90%;
            margin: auto;
        }
        @include tablet {
            width: 90%;
            margin: auto;
            flex-direction: column-reverse;
        }
    }
    .game-inner {
        width: 50%;
        @include tablet {
            width: 100%;
            margin: auto;
        }
    }
    .game-content {
        @include tablet {
            text-align: center;
        }
        .section-title {
            text-align: left;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                bottom: -20px;
                left: 0;
                border-bottom: 9px solid $yellow;
                width: 253px;
            }
            @include tablet {
                text-align: center;
                &::after {
                    right: 0;
                    margin: auto;
                    border-bottom: 6px solid $yellow;
                    width: 120px;
                }
            }
        }
        .game-subtitle {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            @include phone {
                font-size: 20px;
                line-height: 28px;
            }
        }
        .read-more {
            display: none;
            font-size: 18px;
            line-height: 1;
            color: $yellow;
            font-weight: 700;
            margin: 40px auto;
            align-items: center;
            span {
                margin: auto;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    right: -30px;
                    top: 0;
                    bottom: 0;
                    background-image: url("../../images/arrow.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 18px;
                    height: 18px;
                }
            }
            &:hover {
                cursor: pointer;
            }
            @include tablet {
                display: flex;
            }
        }
        .game-text {
            margin-top: 30px;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 27px;
        }
        .game-modes {
            font-size: 18px;
            line-height: 30px;
            position: relative;
            @include tablet {
                .game-modes-wrap {
                    max-height: 0;
                    opacity: 0;
                    overflow: hidden;
                    transition: opacity 0.3s ease-out;
                }
                &.mobile-active {
                    margin: 30px auto;
                    .game-modes-wrap {
                        max-height: 100%;
                        opacity: 1;
                        transition: opacity 0.1s ease-in;
                    }
                }
            }
        }
        .game-modes-title {
            font-weight: 700;
        }
        .game-modes-list {
            font-weight: 300;
            span {
                color: $yellow;
                font-weight: 700;
            }
        }
        .game-link-wrap {
            display: flex;
            margin-top: 40px;
        }
        .button-transformed {
            transform: rotate(-4deg);
            background-color: $white;
            color: $pink;
            margin: auto;
            .game-link {
                color: $pink;
                font-size: 18px;
                font-weight: 800;
                letter-spacing: -0.28px;
                text-align: center;
                display: block;
                transform: rotate(4deg);
                padding: 15px 45px;
                text-decoration: none;
                text-transform: uppercase;
                @include phone {
                    margin: auto;
                }
                &:hover {
                    color: $white;
                }
            }
            @include tablet {
                background-color: $yellow;
            }
            @include phone {
                text-align: center;
            }
            &:hover {
                cursor: pointer;
                color: $white;
                background-color: $pink;
            }
        }
    }
    .game-media {
        width: 45%;
        margin: auto;
        position: relative;
        display: flex;
        img {
            width: 100%;
            height: 90%;
            object-fit: contain;
            z-index: 10;
        }
        @include tablet {
            width: 90%;
            margin-top: 50px;
        }
        .game-media-line {
            position: absolute;
            bottom: 0;
            left: 45%;
            background-image: url("../../images/game-media-line.png");
            background-repeat: no-repeat;
            background-size: contain;
            width: 157px;
            height: 147px;
        }
        @include phone {
            display: none;
        }
    }
    .game-media-mobile {
        display: none;
        @include phone {
            display: flex;
            margin-left: -20px;
            width: 100%;
            margin-top: 50px;
        }
    }
}
