// Global styles
// From Tailwind CSS

// Blocks

.inline-block { display: inline-block; }
.flex { display: flex; }
.inline-flex { display: inline-flex; }
.grid { display: grid; }
.hidden { display: none; }
.visible { visibility: visible; }
.static { position: static; }
.fixed { position: fixed; }
.absolute { position: absolute; }
.relative { position: relative; }


// Fill

.fill-current { fill: currentColor; }
.fill-sunny { fill: #fdef50; }

// Height

.h-full { height: 100%; }
.h-screen { height: 100vh; }
.h-0 { height: 0px; }
.h-1 { height: 0.25rem; }
.h-5 { height: 1.25rem; }
.h-10 { height: 2.5rem; }
.h-16 { height: 4rem; }
.h-6 { height: 1.5rem; }

.h-\[2\.5rem\] { height: 2.5rem; }
.h-\[3rem\] { height: 3rem; }
.h-\[5rem\] { height: 5rem; }
.h-\[10rem\] { height: 10rem; }
.h-\[11rem\] { height: 11rem; }
.h-\[15rem\] { height: 15rem; }

.h-\[2px\] { height: 2px; }
.h-\[24px\] { height: 24px; }
.h-\[60px\] { height: 60px; }
.h-\[64px\] { height: 64px; }

.h-inv-catalog-item-height { height: 10rem; }

.min-h-screen { min-height: 100vh; }
.min-h-\[3rem\] { min-height: 3rem; }
.min-h-\[5rem\] { min-height: 5rem; }
.min-h-\[8rem\] { min-height: 8rem; }
.min-h-\[11rem\] { min-height: 11rem; }


// Width

.w-full { width: 100%; }
.w-mobile-page { width: 20rem; }

.w-5 { width: 1.25rem; }
.w-10 { width: 2.5rem; }
.w-16 { width: 4rem; }

.w-\[6rem\] { width: 6rem; }
.w-\[7rem\] { width: 7rem; }
.w-\[10rem\] { width: 10rem; }
.w-\[13rem\] { width: 13rem; }
.w-\[15rem\] { width: 15rem; }
.w-\[18rem\] { width: 18rem; }
.w-\[20rem\] { width: 20rem; }
.w-\[22rem\] { width: 22rem; }
.w-\[24rem\] { width: 24rem; }
.w-\[30rem\] { width: 30rem; }

.w-\[24px\] { width: 24px; }
.w-\[60px\] { width: 60px; }
.w-\[64px\] { width: 64px; }

.min-w-\[12rem\] { min-width: 12rem; }


// Positions

.-bottom-10 { bottom: -2.5rem; }
.bottom-0 { bottom: 0px; }
.bottom-\[1px\] { bottom: 1px; }
.bottom-5 { bottom: 1.25rem; }
.bottom-\[16px\] { bottom: 16px; }

.top-0 { top: 0px; }
.top-\[30px\] { top: 30px; }
.top-\[80px\] { top: 80px; }
.top-1\/2 { top: 50%; }
.-top-2 { top: -0.5rem; }

.left-0 { left: 0px; }
.left-\[90px\] { left: 90px; }
.left-1\/2 { left: 50%; }
.-left-3 { left: -0.75rem; }
.-left-\[2\.2rem\] { left: -2.2rem; }

.-right-\[22rem\] { right: -22rem; }
.-right-2 { right: -0.5rem; }
.right-2 { right: 0.5rem; }
.right-\[32px\] { right: 32px; }


// z-index

.z-50 { z-index: 50; }
.z-100 { z-index: 100; }


// Margins

.m-auto { margin: auto; }
.m-5 { margin: 1.25rem; }
.m-4 { margin: 1rem; }

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.-mb-10 {
    margin-bottom: -2.5rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.mb-6 {
    margin-bottom: 1.5rem;
}

.ml-4 {
    margin-left: 1rem;
}

.ml-6 {
    margin-left: 1.5rem;
}

.ml-10 {
    margin-left: 2.5rem;
}

.mr-6 {
    margin-right: 1.5rem;
}

.mr-4 {
    margin-right: 1rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mt-7 {
    margin-top: 1.75rem;
}

.-mb-5 {
    margin-bottom: -1.25rem;
}

.-mt-4 {
    margin-top: -1rem;
}

.ml-auto {
    margin-left: auto;
}


// Flex
.flex-1 { flex: 1 1 0%; }
.flex-shrink-0 { flex-shrink: 0; }
.flex-shrink { flex-shrink: 1; }
.flex-grow { flex-grow: 1; }
.grow { flex-grow: 1; }

.basis-1\/2 { flex-basis: 50%; }


// Backgrounds

.bg-navy {
    --tw-bg-opacity: 1;
    background-color: rgb(18 18 49 / var(--tw-bg-opacity));
}
  
.bg-navy-footer {
    --tw-bg-opacity: 1;
    background-color: rgb(42 42 105 / var(--tw-bg-opacity));
}


// Swap for checkbox input

.swap {
    position: relative;
    display: inline-grid;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    place-content: center;
    cursor: pointer;
}
  
.swap>* {
    grid-column-start: 1;
    grid-row-start: 1;
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
  
.swap input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
  
.swap .swap-indeterminate,.swap .swap-on,.swap input:indeterminate~.swap-on {
    opacity: 0;
}
  
.swap input:checked~.swap-off,.swap input:indeterminate~.swap-off,.swap.swap-active .swap-off {
    opacity: 0;
}
  
.swap input:checked~.swap-on,.swap input:indeterminate~.swap-indeterminate,.swap-active .swap-on {
    opacity: 1;
}

.swap-rotate .swap-indeterminate,.swap-rotate .swap-on,.swap-rotate input:indeterminate~.swap-on {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
  
.swap-rotate input:checked~.swap-off,.swap-rotate input:indeterminate~.swap-off,.swap-rotate.swap-active .swap-off {
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
  
.swap-rotate input:checked~.swap-on,.swap-rotate input:indeterminate~.swap-indeterminate,.swap-rotate.swap-active .swap-on {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
  
.swap-flip .swap-indeterminate,.swap-flip .swap-on,.swap-flip input:indeterminate~.swap-on {
    transform: rotateY(180deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    opacity: 1;
}
  
.swap-flip input:checked~.swap-off,.swap-flip input:indeterminate~.swap-off,.swap-flip.swap-active .swap-off {
    transform: rotateY(-180deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    opacity: 1;
}
  
.swap-flip input:checked~.swap-on,.swap-flip input:indeterminate~.swap-indeterminate,.swap-flip.swap-active .swap-on {
    transform: rotateY(0);
}


// Tailwind CSS spacing classes

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}


// Select

.select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
