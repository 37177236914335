.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 60px;
    background-image: url("../../images/hero-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;

    &.mobile-active {
        position: fixed;
        left: 0;
        right: 0;
        height: 100vh;
        z-index: 100;
    }

    // transition: background-color 0.2s ease;
    @include laptop {
        padding: 30px 30px;
    }
    @include desktop {
        padding: 25px 30px;
    }
    @include tablet {
        padding: 25px 25px;
    }
    @include phone {
        padding: 15px 20px;
    }
    @include small-phone {
        padding: 10px 15px;
    }

    .header-logo {
        align-self: center; // align-items: center;

        @include tablet {
            display: block;
            align-self: flex-start; // align-items: center;
            margin-top: 0;
        }

        .logo {
            display: block;
            width: 85px;  // width: 84.39px;
            height: 85px; // height: 78.64px;

            @include laptop {
                width: 80px;
                height: 80px;
            }
            @include desktop {
                width: 75px;
                height: 75px;
            }
            @include tablet {
                width: 75px;
                height: 75px;
            }
    
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        } // logo
    }

    .header-connect {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btn {
        .icon-wallet {
            margin-right: 5px;
            background-image: url("../../images/wallet.svg");
            width: 35px;
            height: 35px;
            background-size: contain;
            background-repeat: no-repeat;
            
            @include phone {
                width: 16px;
                height: 16px;
                margin-right: 2px;
            }
            @include small-phone {
                width: 12px;
                height: 12px;
            }
        }

        &:hover {
            .icon-wallet {
                background-image: url("../../images/wallet-white.png");
                width: 35px;
                height: 35px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    } // btn

    .wallet-adapter-button {
        background-color: $yellow;
        color: $pink;
        font-family: $base-font-family;
        border-radius: 25px;
        font-size: 18px;
        font-weight: 800;

        .wallet-adapter-button-start-icon {
            width: auto;
            height: 27px;
            margin-right: 0;
        }

        &:hover {
            background: $pink;
            color: $white;
        }

        @include tablet() {
        // font-size: 12px;
        }
        @include phone() {
            font-size: 9px;
            padding: 6px 8px;
            height: 25px;
            line-height: 25px;
        }
    } // wallet-adapter-button

    .nav {
        margin-left: 50px;
        margin-right: auto;
        display: flex;
        align-items: center;
        padding: 10px 0;

        // nav in mobile mode
        &.mobile {
            display: flex;
            flex-direction: column;
            width: 60%;
            max-width: 400px;
            margin: 0 auto 60px;
            height: 70%;
            max-height: 90%;
            justify-content: space-between;
        }

        .link {
            font-weight: 900;
            font-size: 18px;
            line-height: 27px;
            text-align: left;
            letter-spacing: -0.276877px;
            text-transform: uppercase;
            color: $white;
            text-decoration: none;
            position: relative;
            width: auto;
            margin-right: 45px; // 60px
            padding-top: 10px;
            padding-bottom: 10px;
            cursor: pointer;

            & > span {
                display: block;
                text-align: center;
            }

            // Bottom animation
            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -8px;
                width: 0;
                height: 3.6px;
                background-color: #fd1d92;
                transition: all 0.3s ease-out;
            }

            &:hover {
                &::after {
                    width: 75%;
                }
                @include desktop {
                    &::after {
                        display: none;
                    }
                }
            }

            @include laptop {
                margin-right: 30px;
            }

            &.has-children {
                &::after {
                    display: none;
                }

                .has-children-arrow {
                    // content: "";
                    position: absolute;
                    top: 20px;
                    right: -15px;
                    background-image: url("../../images/arrow-up.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 10px;
                    height: 6px;
                    transform: rotate(180deg);
                    transition: transform 0.3s ease-in-out;

                    @include tablet {
                        right: 0px;
                    }
                } // has-children-arrow

                @include desktop {
                    &::before {
                        right: 25px;
                    }
                }

                .sub-menu {
                    display: flex;
                    flex-direction: column;
                    background-color: $white;
                    box-shadow: 0px 11px 24px rgba(255, 255, 255, 0.4);
                    position: absolute;
                    left: -30%;
                    right: 0;
                    padding: 20px 30px;
                    width: 163%;
                    opacity: 0;
                    z-index: -1;
                    
                    // transition: opacity 0.3s ease-in-out;
                    .link {
                        margin-right: 0;
                        margin-bottom: 18px;
                        color: #010101;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    @media (min-width: 841px) {
                        transform: matrix(1, -0.04, -0.05, 1, 0, 0);
                        .link {
                            display: none;
                            transform: matrix(1, 0.04, 0.05, 1, 0, 0);
                        }
                    }

                    // Tablet View
                    @media (max-width: 840px) {
                        background-color: transparent;
                        box-shadow: none;
                        height: 0;
                        left: 0;
                        transform: translateY(-50%);
                        transition: transform 0.2s ease-in-out;
                        position: relative;
                        width: 100%;
                        margin: 0 auto;
                        padding: 0;

                        .link {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 19px;
                            color: rgba(255, 255, 255, 0.7);
                            transform: none;
                            margin-bottom: 0;

                            &.active {
                                font-weight: 900;
                                color: $pink;
                            }
                        }
                    }
                } // sub-menu

                @media (min-width: 841px) {
                    &:hover {
                        &::before {
                            transform: rotate(0);
                        }
                        
                        .sub-menu {
                            z-index: 10;
                            opacity: 1;

                            .link {
                                opacity: 1;
                                z-index: 10;
                                display: block;

                                &::after,
                                &::before {
                                    display: none;
                                }
                                &:hover {
                                    color: $pink;
                                }
                            } // link
                        } // sub-menu
                    }
                }

                @media (max-width: 840px) {
                    &.active {
                        &::before {
                            transform: rotate(0);
                        }

                        .sub-menu {
                            opacity: 1;
                            z-index: 10;
                            @include desktop {
                                height: 100%;
                                transform: translateY(0);
                            }
                        }
                    }
                }
            } // has-children
        } // link

        // nav settings for laptop layout
        @include laptop {
            margin-left: 20px;
            .link {
                margin-left: 0;
                margin-right: 30px;
                font-weight: 900;
                font-size: 18px;
                line-height: 27px;
            }
        }

        // nav settings for desktop layout
        @include desktop {
            margin-left: 16px;
            .link {
                margin-left: 0;
                margin-right: 22px;
                font-weight: 900;
                font-size: 16px;
            }
        }

        // nav settings for tablet layout
        @include tablet {
            // Hide header navigation in tablet mode
            display: none;
            .link {
                min-width: 210px;
                margin-left: 0;
                margin-right: 0;
            }
        }
        
    } // nav

    // Social links
    .social {
        display: flex;

        .social-link {
            margin-right: 20px;
            width: 51px;
            height: 50px;

            &:last-child {
                margin-right: 0;
            }

            img {
                width: 100%;
                height: 100%;
            }

            @include tablet {
                width: 30px;
                height: 30px;
            }
            @include phone {
                width: 25px;
                height: 25px;
                margin-right: 11px;
            }
            @include small-phone {
                width: 20px;
                height: 20px;
            }
        }

        &.monkey-header-social {
            .social-link {
                width: 39.25px;
                height: 39.25px;
                margin-right: 14px;
                z-index: 10;

                &:last-child {
                    margin-right: 0;
                }
            }
            @include laptop {
                .social-link {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                }
            }
            @include desktop {
                display: none;
            }
        }
    }

    // Hamburger menu
    .header-hamburger {
        display: none;

        @include tablet {
            display: block;
            align-self: flex-start; // align-items: center;
            margin-top: 20px;
        }

        .hamburger {
            & > span {
                display: block;
                background-color: $white;
                transition: transform 0.2s ease;
                width: 32px;
                height: 4px;
                margin: 6px;
            }
            & > span:nth-child(2) {
                width: 20px;
                margin-left: auto;
                opacity: 1;
            }

            &.active {
                & > span:nth-child(1) {
                    transform: translate3d(0, 10px, 0) rotate(45deg);
                }
                & > span:nth-child(2) {
                    opacity: 0;
                }
                & > span:nth-child(3) {
                    transform: translate3d(0, -10px, 0) rotate(-45deg);
                }
            } // active
        } // hamburger
    } // header-hamburger

    // Launch App Pink Button
    .launch-app {
        align-self: center; // align-items: center;
        margin-left: 15px;

        .launch-app-btn {
            width: 135px; // 135px | 144px | 153px | 162px | 180px
            height: 42px; // 42px  | 45px  | 48px  | 50px  | 56px
            margin: 0 auto;
            padding: 0;
            background-color: #fc168f;
            background-image: url("../../images/launch-app-42.png");
            background-repeat: no-repeat;
            background-size: contain;
            transition: all 0.2s ease-in-out; // transition: transform 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            &.hide-on-tablet {
                display: block;
                @include desktop {
                    width: 72px;  // 72px | 96px
                    height: 42px; // 42px | 56px
                    background-image: url("../../images/launch-app-tablet.png");
                }
                @include tablet {
                    display: none;
                }
            }

            &.show-on-tablet {
                display: none;
                @include tablet {
                    display: block;
                    margin-top: 20px;
                }
            }
        }

        @include laptop {
            margin-left: 10px;
        }
        @include desktop {
            margin-left: 5px; // 10px
        }
        @include tablet {
            margin-left: 0;
        }
    }
} // header

.header {
    &.sticky {
        position: sticky;
        top: 0;
        right: 0;
        left: 0;
        // background: #000;
        z-index: 100;
        // overflow-x: scroll;
        // overflow-y: hidden;
    }
}

.main-content {

    .wallet-adapter-button {
        border-radius: 0;
        transform: rotate(-2deg);
        margin-left: 28px;
        height: 40px;
        font-family: $base-font-family;

        @include tablet {
            padding-left: 20px;
            padding-right: 20px;
        }

        .btn-text {
            transform: rotate(2deg);
            margin-top: 3px;

            @include tablet {
                font-size: 19.8442px;
                line-height: 30px;
            }
        }

        .wallet-adapter-button-start-icon {
            transform: rotate(2deg);
            margin-bottom: 2px;
        }

        @include laptop {
            height: 38px;
            margin-left: 20px;
            .icon-wallet {
                width: 30px;
                height: 30px;
            }
        }

        @include tablet {
            display: none;
        }
    }

    &.mobile-active {
        @include desktop {
            height: 100vh;
            width: 100vw;
            display: flex;
            flex-direction: column;
            padding: 0;
            padding-bottom: 30px;
            overflow: hidden;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1030;
            background: radial-gradient(
                172.86% 93.66% at 54.1% 56.04%,
                #2f2f76 0%,
                #000000 100%
            );
            justify-content: flex-start;

            .header-logo {
                display: flex;
                width: 100%;
                padding-left: 40px;
                padding-top: 16px;
            }

            .monkey-header-social {
                order: 4;
                margin-bottom: 40px;
                &.mobile-active {
                display: flex;
                }
            }
            
            .wallet-adapter-button {
                display: flex;
                margin-bottom: 60px;
                margin-top: 40px;
                @include phone {
                    margin-top: 0;
                    margin-bottom: 30px;
                }
            }
        }
    }
    
    @include phone {
        justify-content: space-between;
    }
}

.wallet-adapter-button.btn-bg-yellow {
    background-color: $yellow !important;
    color: $pink !important;
    font-family: $base-font-family !important;
    border-radius: 25px !important;
    font-size: 18px !important;
    font-weight: 800 !important;
    margin-right: 12px !important;

    .wallet-adapter-button-start-icon {
        width: auto !important;
        height: 27px !important;
        margin-right: 0 !important;
    }

    &:hover {
        background: $pink !important;
        color: $white !important;
    }

    @include tablet() {
        font-size: 12px;
    }
    @include phone() {
        font-size: 12px !important;
        padding: 6px 8px !important;
        height: 25px !important;
        line-height: 25px !important;
    }
}

.wallet-adapter-dropdown {
    @include tablet {
        display: flex !important;
        justify-content: center;
        position: absolute !important;
        right: 80px;
        top: 36px;
    }
    @include phone {
        top: 40px;
    }
}
