.steps {
    margin: 150px auto 0;
    color: $white;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        right: 0;
        top: -400px;
        background-image: url("../../images/bg-circle.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;
        width: 200px;
        height: 300px;
        // opacity: 0.3;
    }
    @include phone {
        margin: 80px auto 0;
        &::before {
            top: -200px;
            width: 150px;
            height: 200px;
        }
    }
    .steps-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1380px;
        z-index: 10;
        @include laptop {
            max-width: 90%;
            margin: auto;
        }
        @include phone {
            width: 90%;
            margin: auto;
        }
        .section-title {
            margin: auto;
        }
    }
    .steps-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        z-index: 10;
        @include desktop {
            justify-content: center;
        }
    }
    .step-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 30%;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: -35px;
            border-right: 1px solid rgba(255, 255, 255, 0.2);
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        @include desktop {
            width: 50%;
            margin-bottom: 50px;
            &:nth-child(2) {
                &::after {
                    display: none;
                }
            }
        }
        @include tablet {
            width: 100%;
            margin-bottom: 100px;
            &::after {
                display: none;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .step-icon {
            width: 128px;
            height: 128px;
            @include phone {
                width: 122.55px;
                height: 122.55px;
            }
        }
        .step-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 30px;
            text-align: center;
            margin: 40px 0 25px;
            @include phone {
                font-size: 30.6373px;
                line-height: 28px;
            }
        }
        .step-text {
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: $white;
            margin: 0;
            max-width: 300px;
            @include phone {
                font-size: 19.1483px;
                line-height: 29px;
            }
        }
        .step-links {
            display: flex;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            margin-top: 10px;
            min-height: 30px;
            .step-link {
                color: $link;
                position: relative;
                margin-right: 10px;
                padding-right: 5px;
                text-decoration: underline;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: -5px;
                    border-right: 3px solid $link;
                    margin: auto;
                    height: 18px;
                    @include tablet {
                        bottom: auto;
                    }
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            @include tablet {
                min-height: 85px;
            }
            @include phone {
                font-size: 19.1483px;
                line-height: 29px;
                min-height: 70px;
                .step-link {
                    &::after {
                        top: 6px;
                    }
                }
            }
        }
        @include phone {
            margin-bottom: 60px;
        }
    }
    .center-wrap {
        margin: 105px auto 50px;
        z-index: 10;
        button {
            padding: 15px 45px;
        }
        .wallet-adapter-button {
            padding: 15px 45px;
            background-color: $yellow;
            color: $pink;
            font-weight: 900;
            font-size: 25.5175px;
            line-height: 38px;
            font-family: $base-font-family;
            transform: rotate(-2deg);
            border-radius: 0;
            .wallet-adapter-button-start-icon {
                display: none;
            }
            &:hover {
                background-color: $pink;
                color: $white;
            }
            .btn-text {
                transform: rotate(2deg);
            }
            @include phone {
                padding: 10px 30px;
                font-size: 19.1483px;
                line-height: 29px;
            }
        }
    }
    .steps-info {
        font-weight: 300;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
        width: 100%;
        max-width: 600px;
        margin: auto;
        z-index: 10;
        @include phone {
            font-size: 11.489px;
            line-height: 17px;
            width: 75%;
            margin: auto;
        }
        @include small-phone {
            width: 90%;
        }
    }
    .swiper-pagination-bullet {
        background-color: $white;
        width: 10.53px;
        height: 10.53px;
    }
}
