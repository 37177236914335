.lp-team-items-wrap {
  width: 100%;

  .swiper-slide {
    height: auto;
  }

  .invalid-monkey-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      text-align: center;
      letter-spacing: 0.00918536px;
      color: #ffffff;
      opacity: 0.2;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    img {
      width: 218px !important;
      height: 374px !important;

      @media (max-width: 768px) {
        width: 100px !important;
        height: 180px !important;
      }
    }
  }

  @media (max-width: 768px) {
    display: flex;
    color: #fff;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    flex-direction: column;
  }

  .lp-team-back {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: start;

    > a {
      color: #fff;
      text-decoration: none;
      margin-left: 7px;
      font-weight: bold;
      background-color: #e6cb00 !important;
      color: #fc0084 !important;
      font-family: "Poppins", sans-serif !important;
      border-radius: 25px !important;
      font-size: 18px !important;
      font-weight: 800 !important;
      padding: 7px 14px;

      @media (max-width: 768px) {
        font-size: 14px !important;
        font-weight: 800 !important;
        padding: 4px 8px;
      }
    }
  }
}

.lp-team-card-wrap {
  color: $white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  @include tablet {
    flex-direction: column;
  }
  .lp-team-card-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 9px;
  }

  .lp-team-item {
    padding: 0 5px;

    &.images {
      max-width: 35%;
      width: 100%;
      // height: 625px;
      // overflow: hidden;
    }

    &:not(.images) {
      max-width: 65%;
      width: 100%;
    }

    @media (max-width: 768px) {
      max-width: 100% !important;
      padding: 0;
      margin-bottom: 15px;
    }
  }

  .lp-team-card-images {
    overflow: hidden;
    background: #111c36;
    border: 1px solid #424261;
    box-sizing: border-box;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      // object-fit: cover;
    }
    @include tablet {
      width: 100%;
    }
    .swiper {
      // height: 100%;
      height: 625px;
    }
    .swiper-slider {
      height: auto;
    }
    .swiper-button-next {
      background-image: url("../../images/landing/array-next.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      top: 31px;
      left: 66px;
      &::after {
        display: none;
      }
    }
    .swiper-button-prev {
      background-image: url("../../images/landing/array-prev.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      top: 31px;
      left: 25px;
      &::after {
        display: none;
      }
    }
  }

  .lp-team-card-content {
    padding: 30px;
    background: #111c36;
    border: 1px solid #424261;
    box-sizing: border-box;
    border-radius: 10px;

    @include laptop {
      padding: 15px 15px 20px 35px;
    }
    @include tablet {
      padding: 15px;
      margin: auto;
    }
  }

  .lp-card-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    padding-right: 3px;
    @include phone {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .lp-card-top-details {
      display: flex;
      align-items: start;
      justify-content: start;
      @include desktop {
        flex-direction: column;
      }
      @include phone {
        align-items: center;
      }
      
      > div {
        padding: 2px 8px;
        border-radius: 5px;
      }
    }

    .lp-card-name-wrap {
      .lp-card-name {
        background-color: $pink;
        color: $white;
        font-size: 26px;
        font-weight: 700;
        line-height: 24px;
        transform: rotate(-2deg);
        padding: 10px;
        margin-bottom: 5px;
        margin-left: -8px;
        span {
          transform: rotate(2deg);
        }
      }
      .lp-card-id {
        font-weight: 300;
        font-size: 17px;
        line-height: 25px;
        color: $white;
        opacity: 0.7;
      }
    }
    .lp-top-details-item {
      display: flex;
      font-size: 17px;
      line-height: 25px;
      color: rgba(255, 255, 255, 0.7);
      .lp-details-value {
        font-weight: 500;
        color: $white;
      }
      .lp-details-name {
        margin: 0 5px;
      }
      .lp-details-icon {
        width: 26.73px;
        height: 14.44px;
        object-fit: contain;
      }
    }
  }
  .question-wrap {
    position: relative;
    line-height: inherit;
    .question {
      margin-left: 5px;
      img {
        width: 11px;
        height: 11px;
        object-fit: contain;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      .text-tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .lp-card-icons {
    display: flex;
    flex-wrap: wrap;
    .lp-card-info-wrap {
      padding: 0 0 10px 10px;
      flex: 1;
      border: 1px solid rgba(66, 66, 97, 0.5);
      box-sizing: border-box;
      border-radius: 10px;
      margin-right: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      @include tablet {
        margin-right: 0;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        padding: 0;
        order: 2;
      }

      .lp-card-info-icon-wrap {
        width: 78px;
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: center;
        // .lp-card-info-icon-active {
        //     width: 130%;
        //     height: 130%;
        //     object-fit: contain;
        //     border-radius: 50%;
        //     transition: opacity 0.4s ease-in-out;
        //     opacity: 1;
        // }
        // .lp-card-info-icon {
        //     height: 130%;
        //     object-fit: contain;
        //     border-radius: 50%;
        //     transition: opacity 0.4s ease-in-out;
        //     opacity: 0;
        //     width: 0;
        // }
      }
      .lp-card-info-title {
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.00859889px;
        text-transform: capitalize;
        color: $white;
        opacity: 0.7;
        margin-top: -10px;
        @include laptop {
          line-height: 25px;
        }
      }
      .lp-card-info-value {
        font-weight: 500;
        font-size: 20.1741px;
        line-height: 38px;
        text-align: center;
        letter-spacing: 0.0102044px;
        color: $white;
      }

      .lp-card-info-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        @include tablet {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 0;
        }
        &:hover {
          cursor: pointer;
          .tooltip {
            opacity: 1;
            visibility: visible;
          }
          // .lp-card-info-icon-active {
          //     opacity: 0;
          //     width: 0;
          //     transition: opacity 0.4s ease-in-out;
          // }
          // .lp-card-info-icon {
          //     opacity: 1;
          //     width: 130%;
          //     transition: opacity 0.4s ease-in-out;
          // }
        }
      }
    }
    .lp-card-icon-max {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      .max-image-question-wrap {
        position: absolute;
        bottom: 5px;
        left: 10%;
        .tooltip-bottom {
          right: -120px;
          &::before {
            right: 60px;
          }
        }
        @include tablet {
          left: 25%;
          .tooltip-bottom {
            left: -70px;
            right: 0;
            &::before {
              left: 0;
              right: 0;
              top: -72px;
            }
          }
        }
        @include phone {
          .tooltip-bottom {
            &::before {
              top: -84px;
            }
          }
        }
      }
      .lp-icon-max-image {
        width: 180px;
        max-height: 140px;
      }
      .lp-icon-max-title {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.00642975px;
        color: $white;
        opacity: 0.7;
        margin-bottom: 4px;
      }
      .lp-icon-max-value {
        font-weight: bold;
        font-size: 28px;
        line-height: 18px;
        letter-spacing: 0.00816084px;
        color: $blue;
      }
      @include tablet {
        width: 100%;
        order: 1;
        margin-bottom: 20px;
      }
      &:hover {
        .text-tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .lp-card-accordion-wrap {
    margin-top: 20px;
    .lp-accordion-inner-wrap {
      border-bottom: 1px solid rgba(66, 66, 97, 0.2);
      padding-bottom: 12px;
      margin-bottom: 15px;
      margin-top: 9px;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 5px;
      }
    }
    .lp-accordion-item-wrap {
      box-sizing: border-box;
      .lp-accordion-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        // margin-bottom: 9px;
        .lp-accordion-top-name {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.6);
          @include phone {
            font-size: 18px;
          }
          @include small-phone {
            font-size: 14px;
          }
        }
        .lp-accordion-top-info {
          color: rgba(255, 255, 255, 0.6);
          display: flex;
          align-items: center;
          @include phone {
            font-size: 14px;
          }
          @include small-phone {
            font-size: 12px;
          }
        }
        .lp-accordion-top-icon {
          width: 16px;
          height: 16px;
          opacity: 0.7;
          margin-right: 5px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          @include phone {
            width: 14px;
            height: 14px;
            margin-right: 3px;
          }
          @include small-phone {
            width: 12px;
            height: 12px;
          }
        }
        .lp-accordion-top-value {
          margin-left: 5px;
          font-weight: bold;
          color: $blue;

          &.defender {
            color: #cbb506;
          }

          &.midfielder {
            color: #eb017c;
          }

          &.goalkeeper {
            color: #2ccf94;
          }
        }
        .arrow-wrap {
          margin-left: 10px;
          transition: transform 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
          }
          @include phone {
            margin-left: 8px;
          }
        }
      }
      .lp-accordion-content {
        opacity: 0;
        height: 0;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        .lp-accordion-item,
        .lp-accordion-column {
          opacity: 0;
          height: 0;
          transition: all 0.3s ease-in-out;
        }
        .lp-accordion-column {
          min-height: 0;
          display: none;
        }
      }
      .lp-accordion-item-title {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.00642975px;
        color: $white;
        text-transform: capitalize;
        font-weight: 400;
      }
      .lp-card-row {
        background-color: rgba(233, 247, 255, 0.1);
        margin: 6px 0 10px;
        border-radius: 55.5px;
        font-size: 12px;
        letter-spacing: 0.00642975px;
        color: $white;
        position: relative;
        z-index: 1;
        height: 18px;
        @include laptop {
          margin-bottom: 6px;
        }
        .lp-card-row-current {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background: $blue;
          border-radius: 55.5px;
          padding: 0 13px;
          width: 50%;
          text-align: right;
          z-index: 11;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &.defender {
            background: #e6cb00;
          }

          &.midfielder {
            background: #eb017c;
          }

          &.goalkeeper {
            background: #2ccf94;
          }

          .row-title {
            margin-right: 3px;
            @include tablet {
              display: none;
            }
          }
          .row-value {
            font-weight: 700;
            margin-left: 3px;
            min-width: 9px;
          }
        }
        .lp-card-row-max {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background: #166595;
          border-radius: 55.5px;
          color: $white;
          padding: 0 13px;
          width: 50%;
          min-width: 13%;
          text-align: right;
          z-index: 10;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &.defender {
            background: #3c3f2b;
          }

          &.midfielder {
            background: #3d1744;
          }

          &.goalkeeper {
            background: #164049;
          }

          .row-title {
            margin-right: 3px;
            @include tablet {
              display: none;
            }
          }
          .row-value {
            margin-left: 3px;
            min-width: 9px;
          }
        }
      }

      .lp-accordion-columns {
        columns: 2;
        column-fill: balance;
        padding-top: 8px;
        @include desktop {
          columns: 1;
        }
      }
      .lp-accordion-column {
        border-radius: 5px;
        color: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 300;
        font-size: 15px;
        line-height: 24px;
        min-height: 33px;
        padding-left: 13px;
        padding-right: 13px;
        @include phone {
          padding-right: 10px;
          padding-left: 10px;
          font-size: 12px;
        }
        &:nth-child(odd) {
          background: rgba(196, 196, 196, 0.1);
        }
        &:nth-child(6) {
          margin-bottom: 20px;
          @include desktop {
            margin-bottom: 0;
          }
        }
        .left {
          display: flex;
          align-items: center;
          width: 27%;
        }
        .right {
          max-width: 73%;
          span {
          text-align: start;    
          // white-space: nowrap;      
          }
        }
        .lp-accordion-column-icon {
          opacity: 0.7;
          width: 23px;
          max-height: 23px;
          margin-right: 15px;
          display: inline-flex;
          display: none;
          img {
            width: 100%;
          }
        }
        .lp-accordion-column-value {
          font-weight: 500;
          color: $blue;

          &.defender {
            color: #cbb506;
          }

          &.midfielder {
            color: #eb017c;
          }

          &.goalkeeper {
            color: #2ccf94;
          }
        }
      }
    }
    .lp-accordion-wrap {
      .lp-accordion-inner-wrap {
        &.active {
          .lp-accordion-item-wrap {
            .lp-accordion-top {
              margin-bottom: 25px;
              @include laptop {
                margin-bottom: 15px;
              }
            }
            .lp-accordion-top-title {
              color: $white;
            }
            .lp-accordion-content {
              opacity: 1;
              height: 100%;
              transform: translateY(0);
              transition: all 0.3s ease-in-out;
              .lp-accordion-item,
              .lp-accordion-column {
                opacity: 1;
                height: 100%;
              }
              .lp-accordion-column {
                min-height: 33px;
                display: flex;
                justify-content: flex-start;
              }
            }
            .lp-accordion-token-details {
              div {
                padding: 4px 8px;
                border-radius: 5px;
                font-weight: 300;
                font-size: 17px;
                line-height: 24px;
                word-break: break-all;
                &:nth-child(odd) {
                  background: rgba(196, 196, 196, 0.1);
                }
                .detail-row-title {
                  width: 135px;
                  display: inline-flex;
                }.detail-row-value {
                  color: #fff;
                  font-size: 15px;
                }
              }
            }
            // .lp-accordion-columns {
            //     display: flex;
            // }
            .arrow-wrap {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
