// Modal classes
.overlay {
    position: fixed;
    z-index: 110;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
}

// Popup classes
// flex flex-col w-[22rem] md:w-[44rem]
.modal-popup {
    box-sizing: border-box;
    display: flex; // flex
    flex-direction: column; // flex-col
    width: 44rem;

    @include tablet {
        width: 22rem;
    }

    // w-full h-full p-2 space-y-4
    .modal-popup-container {
        box-sizing: border-box;
        width: 100%; // w-full
        height: 100%; // h-full
        padding: 0.5rem; // p-2
    }
}

// relative w-10 h-10 rounded-3xl
.close-button {
    box-sizing: border-box;
    position: relative; // relative
    width: 2.5rem; // w-10
    height: 2.5rem; // h-10
    border-radius: 1.5rem; // rounded-3xl
    cursor: pointer;

    &.bg-magenta {
        background-color: rgb(232 60 141);
    }

    // absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
    .close-button-cross {
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            display: block;
        }
    }
}

// relative w-full h-0 overflow-visible
.popup-close-button {
    width: 100%;
    height: 0px;
    position: relative; // relative
    overflow: visible;

    // absolute -top-2 -right-2
    .popup-close {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
    }
}
