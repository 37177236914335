.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    max-width: 133px;
    animation: loader-pump 1.3s infinite alternate ease-in-out;
  }

  .text-wrapper {
    display: flex;
    align-items: center;

    p {
      margin-top: 8px;
      font-size: 22px;
      color: $white;
    }

    .dot-flashing {
      margin-left: 20px;
      position: relative;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: $dark;
      color: $dark;
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: 0.5s;
    }

    .dot-flashing::before,
    .dot-flashing::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
    }

    .dot-flashing::before {
      left: -10px;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: $dark;
      color: $dark;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 0s;
    }

    .dot-flashing::after {
      left: 10px;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: $dark;
      color: $dark;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 1s;
    }
  }
}

@keyframes loader-pump {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: #ebe6ff;
  }
  50%,
  100% {
    background-color: $dark;
  }
}
