.generation {
    position: relative;
    background-image: url("../../images/generation-bg.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding-top: 100px;
}
.generation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    color: $white;
    max-width: 800px;
    .section-title {
        width: 100%;
        max-width: 510px;
    }
    .generation-subtitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 30px;
        @include phone {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
        }
        @include small-phone {
            font-size: 14px;
        }
    }
    .read-more {
        display: none;
        color: $pink;
        text-decoration: underline;
        font-size: 17.2335px;
        line-height: 26px;
        font-weight: 700;
        @include tablet {
            display: flex;
        }
    }
    .generation-text-wrap {
        display: flex;
        flex-direction: column;
        transition: all 0.1s ease-out;
        @include tablet {
            visibility: hidden;
            opacity: 0;
            height: 0;
            transition: all 0.3s ease-out;
        }
        &.mobile-active {
            visibility: visible;
            opacity: 1;
            height: auto;
        }
    }
    .generation-text {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 30px;
        @include phone {
            font-size: 16px;
            line-height: 24px;
        }
        @include small-phone {
            font-size: 14px;
        }
    }
    .generation-info {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.36917px;
        text-align: center;
        a {
            color: $link;
        }
        @include phone {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
