.team-section {
  color: $white;
  background-image: url('../../images/team/team-bg.png');
  background-repeat: no-repeat;
  background-position: bottom center;
  padding-bottom: 150px;
  padding-top: 150px;
  @include tablet {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .team-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1460px;
    margin: auto;
    @include laptop {
      width: 90%;
      max-width: 90%;
      margin: auto;
    }
  }
  .team-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    text-align: center;
    max-width: 1024px;
    font-size: 24px;
    line-height: 30px;
    .section-title {
      margin-bottom: 10px;
    }
    @include phone {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .team-slider {
    margin-top: 100px;
    margin-bottom: 100px;
    overflow: hidden;
    .swiper {
      padding: 0 30px 0 45px;
      @include laptop {
        margin-left: 35px;
      }
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev,
    .swiper-button-next {
      color: $white;
    }

    .cards-wrap {
      display: flex;
      width: 95%;
      margin: auto;
      overflow-x: scroll;
      padding: 0 20px;
      .team-card-wrap {
        min-width: 293px;
        width: 300px;
        margin-right: 25px;
        @include small-phone {
          min-width: 80%;
          margin-right: 20px;
        }
        .team-card {
          width: 100%;
          .team-img {
            width: 100%;
            height: 320px;
            img {
              border-radius: 25px;
            }
            @include small-phone {
              height: 280px;
            }
          }
        }
      }
    }
    @include desktop {
      margin-right: -20px;
      margin-left: -20px;
    }
  }
  .team-card-wrap {
    width: 300px;
    .team-card {
      .team-img {
        height: 349px;
        border-radius: 25px;
        margin: auto;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 25px;
        }
        .team-name {
          position: absolute;
          left: 10px;
          right: 10px;
          bottom: -18px;
          background-color: $pink;
          padding: 10px;
          transform: rotate(-3deg);
          font-size: 24px;
          line-height: 38px;
          font-weight: 700;
          display: flex;
          max-width: fit-content;
          margin: auto;
          @include phone {
            font-size: 22.0121px;
            line-height: 34px;
          }
        }
      }
    }
    .team-role {
      width: 90%;
      margin: 40px auto 20px;
      text-align: center;
      font-size: 20px;
      line-height: 1;
      min-height: 40px;
      @include phone {
        font-size: 18px;
        line-height: 18px;
      }
    }
    .team-social {
      display: flex;
      justify-content: center;
    }
    .team-social-icon {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:last-child {
        margin-right: 0;
      }
      @include phone {
        width: 29.35px;
        height: 29.35px;
      }
    }
  }
}
