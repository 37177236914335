.connect {
  width: 100%;
  margin: auto;

  .connect-info-wrap {
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
