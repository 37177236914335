// Helper classes from Tailwind CSS
// fixed bottom-0 w-full flex flex-col items-center bg-navy-footer
.cookie-consent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 100;
    bottom: 0px;
    background-color: rgb(42 42 105);

    // Top divider line
    border-top: 2px solid rgb(255 255 255 / 0.5);
}

// Tailwind CSS
// flex flex-row flex-wrap md:flex-nowrap w-[20rem] md:w-[44rem] items-center py-4 space-y-3
.cookie-consent-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-top: 0.25rem; // was 1rem
    padding-bottom: 0.25rem; // was 1rem
    width: 44rem;

    @include tablet {
        width: 20rem;
        flex-wrap: wrap;
    }

    // md:basis-4/5 md:pr-16 space-y-2
    .consent-text-wrap {
        padding-right: 4rem;
        flex-basis: 80%;

        @include tablet {
            padding-right: 0;
            flex-basis: auto;
        }
    
        // font-promt text-xl text-sunny uppercase tracking-wide
        .consent-header {
            color: rgb(253 239 80 / 1); // text-sunny
            margin: 0.75rem 0;
            padding: 0;
            font-size: 1.25rem;   // text-xl = 1.25rem
            line-height: 1.75rem; // text-xl = 1.75rem
            text-transform: uppercase; // uppercase
            letter-spacing: 0.025em; // tracking-wide
            font-family: "Bebas Neue"; // font-promt
            vertical-align: middle;
        }

        // text-lilac text-sm text-justify
        .consent-text {
            color: rgb(197 196 255 / 1); // text-lilac
            margin: 0.75rem 0;
            padding: 0;
            font-size: 0.875rem;  // text-sm
            line-height: 1.25rem; // text-sm
            text-align: justify; // text-justify
        }

        // text-sunny underline
        .consent-link {
            color: rgb(253 239 80 / 1); // text-sunny
            text-decoration-line: underline; // underline
        }

    }

    // grow flex flex-col space-y-2 items-center
    .consent-buttons-wrap {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
    }

    // font-promt select-none m-auto underline text-lilac text-lg
    .consent-button-settings {
        margin: auto; // m-auto
        color: rgb(197 196 255 / 1); // text-lilac
        text-decoration-line: underline; // underline
        font-family: "Bebas Neue"; // font-promt
        font-size: 1.125rem;  // text-lg
        line-height: 1.75rem; // text-lg
        cursor: pointer;
    }
}


// flex flex-col space-y-10 m-5 text-cream
.cookie-settings {
    display: flex; // flex
    flex-direction: column; // flex-col
    margin: 1.25rem; // m-5
    color: rgb(247 247 247); // text-cream

    // Fix unreset paragraphs
    p { margin: 0; }

    // flex flex-col space-y-2
    .cookie-settings-container {
        display: flex; // flex
        flex-direction: column; // flex-col

        // font-promt text-4xl text-sunny
        .cookie-settings-header {
            color: rgb(253 239 80 / 1); // text-sunny
            font-size: 2.25rem;  // text-4xl
            line-height: 2.5rem; // text-4xl
            text-transform: uppercase; // uppercase
            font-family: "Bebas Neue"; // font-promt
        }

        // text-xs md:text-base
        .cookie-settings-text {
            font-size: 1rem;     // text-base
            line-height: 1.5rem; // text-base

            @include tablet {
                font-size: 0.75rem; // text-xs
                line-height: 1rem;  // text-xs
            }
        }

        // text-sunny underline
        .cookie-settings-link {
            color: rgb(253 239 80 / 1); // text-sunny
            text-decoration-line: underline; // underline
        }
    }

    // flex flex-row my-6
    .cookie-settings-grid {
        display: flex; // flex
        flex-direction: row; // flex-row
        margin-top: 1.5rem; // my-6
        margin-bottom: 1.5rem; // my-6
    }
} // cookie-settings


// flex flex-col-reverse md:flex-row space-x-5 items-center
.cookie-buttons-wrap {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    // w-full md:basis-1/3
    .cookie-accept-all {
        width: 100%; // w-full
        flex-basis: 33.333333%;

        @include tablet {
            flex-basis: auto;
        }
    }

    // font-promt text-2xl text-cream underline select-none mb-4 md:mb-0
    .cookie-save-settings {
        cursor: pointer;
        font-family: "Bebas Neue"; // font-promt
        font-size: 1.5rem; // text-2xl
        line-height: 2rem; // text-2xl
        color: rgb(247 247 247); // text-cream
        text-decoration-line: underline; // underline
        margin-bottom: 1rem; // mb-4

        @include tablet {
            margin-bottom: 0; // mb-0
        }
    }
}


// Consent Topic
// flex items-start space-x-4
.consent-topic {
    display: flex; // flex
    align-items: flex-start; // items-start

    // flex flex-col
    .consent-topic-container {
        box-sizing: border-box;
        display: flex; // flex
        flex-direction: column; // flex-col

        // text-sunny text-base font-medium
        .consent-topic-title {
            color: rgb(253 239 80 / 1); // text-sunny
            font-size: 1rem;     // text-base
            line-height: 1.5rem; // text-base
            font-weight: 500; // font-medium
        }

        .consent-topic-desc {
            font-size: 0.875rem;  // text-sm
            line-height: 1.25rem; // text-sm

            @include tablet {
                font-size: 0.75rem; // text-xs
                line-height: 1rem;  // text-xs
            }
        }
    }
}
