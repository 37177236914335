.faqs {
    color: $white;
    padding-top: 250px;
    padding-bottom: 150px;
    @include phone {
        padding-top: 80px;
        padding-bottom: 100px;
    }
    .faqs-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 1460px;
        margin: auto;
        position: relative;
        padding-bottom: 150px;
        @include laptop {
            width: 90%;
            max-width: 90%;
            margin: auto;
        }
        @include phone {
            flex-direction: column-reverse;
            padding-top: 80px;
            padding-bottom: 50px;
        }
        .faqs-inner {
            width: 50%;
            @include tablet {
                width: 100%;
                margin: auto;
            }
            .section-title {
                margin: 0;
                margin-bottom: 20px;
                text-align: left;
            }
        }
        .faqs-media {
            img {
                width: 80%;
                position: relative;
                z-index: 10;
                @include tablet {
                    width: 100%;
                }
                @include phone {
                    margin-left: 25%;
                    width: 100%;
                }
            }
            .faqs-media-img {
                position: relative;
                transition: all 0.6s ease;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50px;
                    left: -100px;
                    height: 438px;
                    width: 438px;
                    background-image: url('../../images/faqs-circle.png');
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: contain;
                    z-index: 1;
                    @include phone {
                        width: 220px;
                        height: 220px;
                        left: auto;
                        right: -20%;
                        bottom: -150px;
                        top: auto;
                    }
                    @include small-phone {
                        width: 200px;
                        height: 200px;
                    }
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -100px;
                    right: 100px;
                    height: 318px;
                    width: 242px;
                    background-image: url('../../images/faqs-line.png');
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: contain;
                    z-index: 1;
                    @include tablet {
                        right: 0;
                    }
                    @include phone {
                        width: 192px;
                        height: 146px;
                        right: auto;
                        left: -10%;
                        bottom: 55px;
                    }
                    @include small-phone {
                        width: 140px;
                        height: 100px;
                        bottom: 75px;
                    }
                }
                @include tablet {
                    margin-bottom: 100px;
                }
                @include phone {
                    margin-bottom: 0;
                }
            }
            .mobile-line {
                display: none;
                @include phone {
                    display: block;
                    position: absolute;
                    width: 300px;
                    height: 300px;
                    left: -10%;
                    right: auto;
                    bottom: -50px;
                    top: auto;
                    background-image: url('../../images/game-line.png');
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: contain;
                    z-index: 1;
                    @include small-phone {
                        width: 240px;
                        height: 240px;
                        bottom: -10px;
                    }
                }
            }
        }
    }

    .accordion-item {
        border-bottom: 1px solid rgba(151, 151, 151, 0.4);
        &:last-child {
            border-bottom: 0;
        }
        &:hover {
            cursor: pointer;
        }
        .accordion-title-wrap {
            display: flex;
            align-items: center;
            padding: 33px 40px 42px 30px;
            @include phone {
                padding: 26px;
            }
            .accordion-title {
                font-weight: 700;
                font-size: 24px;
                line-height: 30px;
                color: $white;
                @include phone {
                    font-size: 17.2335px;
                    line-height: 18px;
                }
            }
        }
        .accordion-content {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 20px;
            padding-bottom: 20px;
            position: relative;
            padding-left: 24px;
            display: none;
            @include phone {
                font-size: 14px;
                line-height: 21px;
            }
            p {
                margin: 0;
            }
            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 20px;
                left: 5px;
                border-left: 2px solid #99ffd8;
                height: 85%;
                margin: auto;
            }
        }
        .accordion-icon {
            width: 28px;
            height: 30px;
            font-size: 40px;
            line-height: 30px;
            margin-right: 30px;
            @include phone {
                margin-right: 8px;
                font-size: 25px;
            }
        }
        &.active {
            .accordion-title-wrap {
                background-image: url("../../images/button-bg.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: right bottom;
                margin-bottom: 35px;
                @include phone {
                    margin-bottom: 20px;
                    padding: 16px;
                }
            }
            .accordion-content {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
