.roadmap {
    color: $white;
    padding-top: 400px;
    margin-top: -100px;
    position: relative;
    @include laptop {
        padding-top: 250px;
        margin-top: 0;
    }
    .roadmap-hidden {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url("../../images/roadmap-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        z-index: 1;
        opacity: 1;
        transition: opacity 0.1s ease-in-out;
    }
    .roadmap-shown {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url("../../images/roadmap-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
    }
    &.active {
        .roadmap-hidden {
            opacity: 0;
            z-index: -1;
            transition: opacity 0.3s ease-in-out;
        }
        .roadmap-shown {
            opacity: 1;
            z-index: 1;
            transition: opacity 0.3s ease-in-out;
        }
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 350px;
        background-image: url("../../images/bg-shadow.png");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        @include desktop {
            height: 200px;
        }
        @include phone {
            height: 150px;
        }
    }
    @include phone {
        padding-top: 100px;
    }
    .container {
        flex-direction: column;
        justify-content: center;
    }
    .section-title {
        z-index: 2;
    }
    .roadmap-list {
        width: 100%;
        max-width: 677px;
        margin: auto;
        z-index: 2;
    }
    .roadmap-item {
        position: relative;
        margin-bottom: 100px;
        @include tablet {
            display: flex;
        }
        &:last-child {
            @include tablet {
                margin-bottom: 40px;
            }
        }
        .roadmap-item-number {
            width: 58px;
            height: 58px;
            margin: 0 auto;
            background-color: $pink;
            border-radius: 50%;
            color: #8b1b54;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
            position: absolute;
            left: 0;
            right: 0;
            top: 29px;
            &::before {
                content: "";
                position: absolute;
                bottom: 58px;
                right: 0;
                left: 0;
                width: 2px;
                height: 149px;
                background-color: #535369;
                margin: 0 auto;
                z-index: 1;
            }
            .number-pulse {
                content: "";
                position: absolute;
                width: 87px;
                height: 87px;
                background: #fa17857d;
                border-radius: 50%;
                display: none;
                transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
                z-index: 2;
            }
            .pulse-large {
                content: "";
                position: absolute;
                width: 116px;
                height: 116px;
                background-color: #fa178547;
                border-radius: 50%;
                display: none;
                transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
                z-index: 2;
            }
            &.active {
                .number {
                    color: $white;
                    z-index: 10;
                }
                .number-pulse {
                    display: flex;
                }
            }
            @include tablet {
                position: relative;
                margin: 0 30px 0 15px;
                width: 39.78px;
                height: 39.78px;
                .number-pulse {
                    width: 66.84px;
                    height: 66.84px;
                    display: flex;
                    background-color: rgba(251, 40, 147, 0.28);
                    color: $white;
                }
                &::before {
                    bottom: 54px;
                    height: 140px;
                }
            }
        }
        .roadmap-item-content {
            max-width: 270px;
            height: 107px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include phone {
                max-width: 250px;
            }
            .roadmap-title {
                font-weight: 700;
                font-size: 24px;
                line-height: 30px;
                color: $yellow;
                margin-bottom: 5px;
                @include phone {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            .roadmap-text {
                font-size: 24px;
                line-height: 36px;
                color: rgba(255, 255, 255, 0.7);
                @include phone {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
        &:nth-child(odd) {
            .roadmap-item-content {
                margin-left: auto;
                @include tablet {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        &:nth-child(even) {
            .roadmap-item-content {
                margin-right: auto;
                @include tablet {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        &:first-child {
            .roadmap-item-number {
                &::before {
                    display: none;
                }
            }
        }
    }
    .more {
        display: flex;
        justify-content: center;
        z-index: 10;
        img {
            transition: transform 0.4s ease-out;
        }
        &:hover {
            cursor: pointer;
        }
        &.active {
            img {
                transform: rotate(180deg);
            }
        }
        @include tablet {
            justify-content: flex-start;
            margin-left: 6px;
        }
    }
}
